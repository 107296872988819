import * as React from 'react';

import { Quote, Image } from './utils';
import tuTheTho1961 from '../../../images/luocsu/tuthetho1961.PNG';
import ducTinhVuongPhuHuu from '../../../images/luocsu/ductinhvuongphuhuu.PNG';
import thongLenh1 from '../../../images/luocsu/thonglenh_small_1.PNG';
import thongLenh2 from '../../../images/luocsu/thonglenh_small_2.PNG';
import paths from '../../shared/url';

export default function Chuong3() {
  return (
    <div className={'section chuong 3-nhiep-do-ha-lai'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>III. Thời Nhiếp Độ Hạ Lai</span>
        </div>
        <div className={'text'}>
          <p>
            Mãi đến năm Phật lịch 2.500 (1956 Tây lịch) Ngài bắt đầu âm
            thầm truyền thiền chỉ bày tu Phật cho những người có duyên
            gần gũi Ngài trên đường dọc ngang đó đây buôn bán.
          </p>
          <p>
            Do duyên một số người tín tâm cùng nhau
            suy tôn Ngài là bậc Thầy dạy đạo, cùng cung thỉnh Ngài khai
            đạo. Bấy giờ Ngài chấp thuận. Ngày 12/01/1961, Ngài dụng Tôn
            giáo Pháp Tạng thành lập Pháp Tạng Phật Giáo Việt Nam.
            Ngài đứng trên cương vị một vị
            Tăng Chủ, pháp hiệu Tịnh Vương, đạo hiệu Nhất Tôn, trong phong
            thái tối ư kín nhiệm, quá ư bình dị thanh bạch dưới tưởng phát
            một Thiện tri thức Cư nhân hạnh đồng sự hợp hóa.
          </p>
          <Image src={tuTheTho1961} height={300}
                 caption={'Chân dung Đức Tịnh Vương Nhất Tôn - 1961'}/>
          <Quote source={'Phụng trích Di ngôn 12/01/1991'}>
            Pháp Tạng có một ý kiến hay là mỗi một con người lấy Như
            Lai Tạng làm gốc, mỗi con người đều là thể tánh của như lai
            nhưng mà do tạng thức làm thành con đường sanh tử. Lúc bấy
            giờ tu hành, một là phải phá vô minh, phá nghi chấp, hóa giải
            tâm để cho tự tánh tỏ tánh ra, thì lúc bấy giờ minh tâm kiến
            tánh được. Mà tỏ cái tánh được thì tất nhiên vô minh phải
            tiêu lần, tiêu lần thì phá được cái tạng. Cái tạng mình khi mà
            nó hòa ra rồi, tan ra rồi thì nó trở vể với thể tánh gọi là
            như lai. Cho nên Pháp Tạng này cái gốc là lấy cái đường hướng
            của nó tất nhiên Như lai tạng gọi là Pháp tạng.
          </Quote>
          <p>
            Năm 1962, Ngài chứng minh khai đạo tràng đầu tiền tại xã
            Vĩnh Ích (sau này Việt Nam thống nhất đổi tên thành xã Ninh
            Ích), làng Phú Hữu, huyện Ninh Hòa, tỉnh Khánh Hòa. Thời gian này Ngài
            truyền lại Giáo Pháp (tập I rồi đến tập II).
          </p>
          <Image src={ducTinhVuongPhuHuu} height={150}
                 caption={'Đức Tịnh Vương dạy đạo tại Ninh Ích (Phú Hữu)'}/>
          <Image srcs={[thongLenh1, thongLenh2]} height={400}
                 caption={<span>Văn bản ban hành Pháp Tạng Hội thượng, ngày 10-03-1968. <a href={paths.hichHoiThuong} target={'_blank'}>Đọc văn bản.</a></span>} />
        </div>
      </div>
    </div>
  );
}
