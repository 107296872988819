
const paths = {
  index: '/',

  // Main sub-pages
  phapMon: '/phap-mon-nhu-lai-tang',
  veChungToi: '/ve-chung-toi',
  luocSu: '/luoc-su-duc-thay/chuong-1',
  kinhSach: '/kinh-sach',
  anhHoatDong: '/anh-hoat-dong',

  // Others
  giayPhepTPHCM: '/giay-phep-sinh-hoat-tphcm',
  giayPhepQN: '/giay-phep-sinh-hoat-qn',
  hichHoiThuong: '/hich/phap-tang-hoi-thuong',
  hichVuTru: '/hich/chung-minh-vu-tru',
  hichBoTat: '/hich/ban-hanh-chu-bo-tat',
}
export default paths;
