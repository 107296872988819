import * as React from 'react';

import * as utils from './utils';
import vutru1 from '../../../images/luocsu/vutru_small_1.png';
import vutru2 from '../../../images/luocsu/vutru_small_2.png';
import anchi4 from '../../../images/luocsu/hinhanchi4.png'
import hinh67 from '../../../images/luocsu/hinh67.png';
import hinh68 from '../../../images/luocsu/hinh68.png';
import hinh69 from '../../../images/luocsu/hinh69.png';
import hinh70 from '../../../images/luocsu/hinh70.png';
import hinh71 from '../../../images/luocsu/hinh71.png';
import paths from '../../shared/url';

export default function Chuong8() {
  return (
    <div className={'section chuong 8-tan-do-ha-lai-1'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>VIII. Thời Tận Độ Hạ Lai (phần 1)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1975, sau ngày Việt nam chấm dứt chiến tranh thống nhất đất nước.
            Ngài đóng cửa đạo tràng tạm ngưng mọi hoạt động tôn giáo. Suốt sáu
            năm liền về sau Ngài không nhận người nhập đạo, dành nhiều thời
            gian cho việc viết các kinh pháp Như lai tạng. Vào lúc 02 giờ sáng
            ngày mùng 9 tháng 9 năm Ất mão (03-10-1975) Ngài nhập chánh định
            tại tịnh thất 42 Hồng Bàng truyền Hịch chứng minh vũ trụ.
          </p>
          <utils.Image srcs={[vutru1, vutru2]}
                 height={400}
                 caption={<span>Ảnh văn bản Hịch chứng minh vũ trụ. <a href={paths.hichVuTru} target={'_blank'}>Đọc văn bản.</a></span>} />
          <utils.Quote source={'Phụng trích tập Giáo ngôn 1974'}>
            "Đương thời nầy chính là thời Hạ Lai, vì sao gọi là thời Hạ Lai?
            - Vì từ thời Thượng kiếp qua Trung kiếp đến Hạ kiếp, do đó nên Hành
            Dụng Như Lai Tận Độ gọi là Hạ Lai.
            Giữa thời Hạ Lai chính là thời Tận Độ. Vì Nhân loại nhiều tự ngã, lắm Lý
            chướng Sự chướng nên do đó khó tu. Hai nữa cầu đạo Vô Thượng
            vướng nơi ảo tưởng nhiều, tham vọng lắm, nương tựa căn, lợi dụng căn,
            háo danh căn để cầu đạo. Thật rất hiếm bậc Thiết tha tâm, Thành thật
            tâm, Thù thắng tâm, nên chi sự cầu đạo kia mới vừa hiểu đôi chút đã ngộ
            nhận mình biết, vừa biết đã chấp nhận mình giác, tu chưa trọn tri kiến đã
            vội thọ chấp sở đắc chánh giác mà an trụ, dù vô tình lầm lẫn hoặc cố ý
            chấp mê thọ ngã chăng cũng vẫn đánh giá Bảo Pháp Chí Tôn Chân
            Truyền vào nơi lạc lõng, gọi là Mạt pháp."
          </utils.Quote>
          <utils.Image src={anchi4}
                       height={400}
                       caption={'"Khi mà Tôi ngồi chụp hình, đó là lúc Tôi viết Ấn chỉ IV - “Dưới Sự\n' +
                         'Nhận Định Vũ Trụ Với Con Người”. Tôi đã tìm ra được một chất nguyên\n' +
                         'tinh trong vũ trụ". (lời Di ngôn). Ảnh năm Bính thìn 1976 – Dưới mái\n' +
                         'hiên tịnh thất 42 Hồng Bàng - Nha Trang. Biểu tượng kết thúc giòng pháp\n' +
                         'Hạ lai Đồng độ.'} />
          <utils.Image src={hinh67}
                       height={200}
                       caption={'Đức Tăng Chủ (trên ghế) cùng các chân tử ở Sài gòn du ngoạn thảo cầm\n' +
                         'viên -1976. (từ trái: ông Pháp Phùng, ông Tạng Bản, ông Tạng Trực, ông Pháp\n' +
                         'Hảo, v.v…)'} />
          <utils.Image src={hinh68}
                       height={200}
                       caption={'Chân tử Diệu Sanh (đứng đầu hàng thứ 2 từ trái) và Diệu Minh (đứng\n' +
                         'đầu từ phải) ở thành phố Vũng Tàu cung thỉnh Đức Tăng Chủ (đeo kính râm)\n' +
                         'giá lâm Vũng Tàu. Ảnh tại sân nhà Cụ Tạng Trung (đứng bên phải Đức Tăng\n' +
                         'Chủ)- 51 đường Phan Chu Trinh, Vũng Tàu 09-12-1977'} />
          <utils.Image src={hinh69}
                       height={200}
                       caption={'Đức Tăng Chủ (đeo kính râm) cùng gia đình Cụ Tạng Trung. Vũng Tàu.1977'} />
          <utils.Image srcs={[hinh70, hinh71]}
                       height={200}
                       caption={'Đức Tăng Chủ cùng các chân tử ở Sài Gòn Vũng Tàu du ngoạn Bãi sau Vũng\n' +
                         'Tàu. 09-12-1977.'} />
        </div>
      </div>
    </div>
  );
}
