import { Image, Poem } from './utils';
import tuTheTho1956 from '../../../images/luocsu/tuthetho1956.PNG';

export default function Chuong2() {
  return (
    <div className={'section chuong 2-cu-nhan-chung-dao'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>II. Thị Hiện Cư Nhân Chứng Đạo</span>
        </div>
        <div className={'text'}>
          <Image src={tuTheTho1956} height={300}
                 caption={'Ông Từ Thế Thọ đưa Thân Mẫu Du Hành Sài Gòn năm 1956'}/>
          <p>
            Năm 1945, khi 27 tuổi, Ngài vào rừng Phú Nhiêu, vùng núi
            phía Tây tỉnh Bình Định miền Trung Việt Nam, chuyên tu thiền
            tọa cốc. Qua bảy tháng tọa thiền Ngài thân chứng Như Lai Tạng
            tại đây.
          </p>
          <p>
            Ngài hạ sơn, chuyển gia đình đến thị trấn Bình Định, vừa
            làm nghề tráng phim ảnh (de nuit), vừa đi buôn bán khắp các
            tỉnh miền Trung và Nam Việt Nam.
          </p>
          <Poem title={'KỆ [khuyết đề]'}  lines={[
            'Ba hồi trống giục vượn leo cây',
            'Bầy khỉ khoanh tay giữa lúc nầy',
            'Ma quỉ điều quy trông rạng rỡ',
            'Lộ hình tượng trắng sẽ sang đây.',
          ]} source={'Tịnh Vương. 1956'} />
        </div>
      </div>
    </div>
  );
}
