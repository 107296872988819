import './index.scss';
import lotusIcon from '../../../images/lotus.png';

export default function LotusDivider(props) {
  return (
    <div style={{
      marginTop: props.marginTop || 15,
    }} className={'lotus-divider'}>
      <img src={lotusIcon} />
      <img src={lotusIcon} />
      <img src={lotusIcon} />
    </div>
  )
}
