import './index.scss';

function MenuIcon(props) {
  let clazz = 'MenuIcon';
  if (props.show) {
    clazz += ' show';
  }
  return (
    <div className={clazz} onClick={props.onClick}>
      <div />
      <div />
      <div />
    </div>
  );
}

export default MenuIcon;
