import * as React from 'react';

function Caption(props) {
  return (
    <div className={'caption'}>
      {props.caption}
    </div>
  );
}

export function Image(props) {
  let srcs = props.srcs;
  if (props.src) {
    srcs = [props.src];
  }
  return (
    <div className={'hinh-anh'}>
      <div className={'images'}>
        {srcs.map((s, idx) => {
          const style = {};
          if (idx > 0 && props.marginBetween) {
            style['marginLeft'] = props.marginBetween;
          }
          return (
            <img src={s}
                 key={s}
               style={style}
               width={props.width}
               height={props.height} />
          );
        })}
      </div>
      <Caption caption={props.caption} />
    </div>
  );
}

// TODO Try style it like https://stackoverflow.com/questions/14585101/old-paper-background-texture-with-just-css
export function Poem(props) {
  return (
    <div className={'poem-container'}>
      <div className={'poem'}>
        <p className={'title'}>{props.title}</p>
        {props.lines.map((line, idx) => <p key={props.title+'-line-'+idx} className={'line'}>{line}</p>)}
        <p className={'source'}>{props.source}</p>
      </div>
    </div>
  );
}

export function Quote(props) {
  return (
    <div className={'quote-container'}>
      <div className={'quote'}>
        <p>{props.children}</p>
        <p className={'source'}>{props.source}</p>
      </div>
    </div>
  )
}
