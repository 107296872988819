import * as React from 'react';
import './index.scss';
import Page, { PageSection } from '../../shared/page';
import paths from '../../shared/url';

export default class KinhSachPage extends React.Component {
  render() {
    return (
      <Page pageName={'KinhSachPage'} twoLineTitle={[
        'Nhất Tôn Đại Tạng Kinh',
        'Thư mục tổng quát',
      ]}>
        <PageSection sectionName={'part-1'}>
          <h4>NHẤT TÔN ĐẠI TẠNG KINH tập I</h4>
          <h4><i>KINH PHÁP HOÁ ĐỘ THỜI KỲ I:</i></h4>
          <h4><i>HẠ LAI ẨN CƯ GIÁO HOÁ. 1956-1968</i></h4>
          <ul className={'kinh-list'}>
            <li>
              <a href={'https://drive.google.com/file/d/1MOwehRgzrbn_GX2_r80DKOq0qtnuHma4/view'} target={'_blank'}>
                Giáo Pháp
              </a>
              <i> (32 bài pháp)</i></li>
              <ul>
                <li>Duy nhất pháp môn Như lai tạng. Đại phương tiện Pháp Tạng. Thời hai.</li>
                <li>Tuyên rõ Nhất Tôn.</li>
              </ul>
            <li>
              <a href={'https://drive.google.com/file/d/1DsjjbmQeS-w8x-wOL2jSbuC5BxPQBPU4/view'} target={'_blank'}>
                Tập thơ Phật đạo:
              </a>
              <i> Tập thơ bảo lưu. Tập thơ phụng lục (1956~1991)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1nMVoSBHELhc4i4SEq-tMMD3C0TOLXin9/view'} target={'_blank'}>
                Trích biên
                <i> (biên lục một số giáo ngôn khai thị)</i>
              </a>
            </li>
          </ul>
        </PageSection>
        <PageSection sectionName={'part-2'}>
          <h4>NHẤT TÔN ĐẠI TẠNG KINH tập II</h4>
          <h4><i>KINH PHÁP HOÁ ĐỘ THỜI KỲ II:</i></h4>
          <h4><i>ĐỒNG ĐỘ HẠ LAI. 1968-1980</i></h4>
          <ul className={'kinh-list'}>
            <li>
              <a href={paths.hichHoiThuong} target={'_blank'}>
                Hịch chứng minh ban hành Pháp Tạng Hội Thượng
              </a>
              <i> (10-3-Mậu thân. 1968)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1anKIbSbMcNKyeBoiiABXkMhOnRbIvlwy/view'} target={'_blank'}>
                Giáo lý trên con đường giải thoát. Tập I
              </a>
              <i> (1971)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1c5Rrepez2jHSJ1bOhcQ35wkVF0Ze30PI/view'} target={'_blank'}>
                Kinh Vô sư
              </a>
              <i> (1972-1973)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1SjfJGHaU4bXV89R8dzgfjdVZbStuTR-1/view'} target={'_blank'}>
                Bảo phẩm Xuất thế chơn kinh
              </a>
              <i> (26-8-Giáp dần. 10-09-1974)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1HeJ-M28g_3zkQh3ZJIkmt0bRgdyNGR3K/view'} target={'_blank'}>
                Giáo ngôn
              </a>
              <i> (29 biên bản sinh hoạt đạo tràng - 1974)</i>
            </li>
            <li>
              <a href={paths.hichVuTru} target={'_blank'}>
                Hịch chứng minh vũ trụ
              </a>
              <i> (ngày 13-10-1975)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/14x086WdERrQdW-42pbV1ladqwlpuvnIV/view'} target={'_blank'}>Ấn Truyền Như Lai Thiền</a>
              <i> (viết xong ngày 26-6-1976)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1DsVuRU_BU287PlIIEKUAeWWDGNr0Faho/view'} target={'_blank'}>
                Chân Nguyên Thực Tế Luận
              </a>
              <i> (1976)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1BqYIuYUuuCGy7NdnGHWkClR0OG5vmJtc/view'} target={'_blank'}>
                Đạo Phật với khoa học
              </a>
              <i> (viết xong 26-8-Bính thìn. 19-09-1976)</i>
            </li>
            <li>
              <a href={paths.hichBoTat} target={'_blank'}>
                Hịch ban hành chư Bồ tát, Bồ tát ma ha tát
              </a>
              <i> (07 tháng Chạp năm Bính thìn. 25-1-1977)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/14nN0n3impbggk5MRILQV2zub4u61OVtj/view'} target={'_blank'}>
                Vô thượng Chân Tôn. Hoá giải lục đạo
              </a>
              <i> (05-Giêng-Đinh tị. 22-02-1977)</i>
            </li>
            <li>Pháp độ thời Đồng độ:</li>
              <ul>
                <li>
                  <a href={'https://drive.google.com/file/d/14nN0n3impbggk5MRILQV2zub4u61OVtj/view'} target={'_blank'}>
                    Ấn chỉ I.
                  </a>
                  <i> (20-Giêng-Đinh tị. 9-3-1977)</i>
                </li>
                <li>
                  <a href={'https://drive.google.com/file/d/14nN0n3impbggk5MRILQV2zub4u61OVtj/view'} target={'_blank'}>
                    Ấn chỉ II.
                  </a>
                  <i> (06-Hai-Đinh tị. 25-3-1977)</i>
                </li>
                <li>
                  <a href={'https://drive.google.com/file/d/14nN0n3impbggk5MRILQV2zub4u61OVtj/view'} target={'_blank'}>
                    Ấn chỉ III.
                  </a>
                  <i> (20-Tư-Đinh tị. 6-6-1977)</i>
                </li>
                <li>
                  <a href={'https://drive.google.com/file/d/1m4eLfbQbFdnRQPF_mGFHfUfiM-z1x_xV/view'} target={'_blank'}>
                    Ấn chỉ IV. Dưới sự nhận định vũ trụ với con người
                  </a>
                  <i> (8-7-Đinh tị. 22-8-1977)</i>
                </li>
              </ul>
          </ul>
        </PageSection>
        <PageSection sectionName={'part-3'}>
          <h4>NHẤT TÔN ĐẠI TẠNG KINH tập III</h4>
          <h4>TẬN ĐỘ HẠ LAI 1980-1985</h4>
          <ul className={'kinh-list'}>
            <li>
              <a href={'https://drive.google.com/file/d/14nN0n3impbggk5MRILQV2zub4u61OVtj/view'}>
                Khai Thị Ngộ Nhập. Bốn tướng giải thoát
              </a>
              <i> (18 tháng Chạp năm Kỷ mùi. 4-2-1980)</i>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1ooIH2-0rG3vhhkHq6wcfsWNrC9hGTO_j/view'} target={'_blank'}>
                Biệt Tôn Vô Thượng Đẳng
              </a>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1m4eLfbQbFdnRQPF_mGFHfUfiM-z1x_xV/view'} target={'_blank'}>
                Giáo ngôn trực chỉ - Ấn chỉ V
              </a>
            </li>
          </ul>
        </PageSection>
        <PageSection sectionName={'part-4'}>
          <h4><i>KINH PHÁP HOÁ ĐỘ THỜI KỲ III:</i></h4>
          <h4><i>THỜI TRANG NGHIÊM CHƠN TƯỚNG NIẾT BÀN. 1985-1993</i></h4>
          <ul className={'kinh-list'}>
            <li>
              <a href={'https://drive.google.com/file/d/1N5OUc3mPYXFGzKqDqusVHwRrgbCw-r95/view'} target={'_blank'}>
                Khai minh Chân lý Thực tiễn
              </a>
            </li>
            <li>
              <a href={'https://drive.google.com/file/d/1MtnPq2-9BhaZVtLMYDjRYOKI5IGCZix-/view'} target={'_blank'}>
                Đức Vô Thượng Tôn ấn quyết
              </a>
            </li>
          </ul>
        </PageSection>
      </Page>
    );
  }
}
