import * as React from 'react';
import './index.scss';
import Page, { PageSection } from '../../shared/page';

import vutru1 from '../../../images/luocsu/vutru1.png';
import vutru2 from '../../../images/luocsu/vutru2.png';

export default class HichVuTruPage extends React.Component {
  render() {
    return (
      <Page pageName={'HichVuTruPage HichPage'} pageTitle={'Hịch Chứng minh Vũ trụ'}>
        <PageSection sectionName={'text'}>
          <p>
            Ta. Tịnh Vương Nhất Tôn, đương lai hạ sanh Tôn Phật. Tối Thượng Đẳng đương thời đảm nhiệm hạ lai trọng trách Đại Diện Tam Thế Phật chứng minh thời Mạt Pháp.
          </p>
          <p>
            Trước sự hiện diện nơi Ta gồm có: Tam Thế Phật chứng giám. Chư Bồ Tát thọ trì, chư Hộ Pháp, Hộ Pháp Bồ Tát đảm nhiệm thi hành lời chứng minh của Ta, cùng các chư thiên hộ pháp, chư thần hộ pháp, như: Nhân Thần, Địa Thần, thượng Đẳng Đẳng Thần, chư Long Thần cùng Tứ Bộ Thần đồng tề tựu, lại có Chư Bồ Tát thời Quá Khứ đã nguyện hộ trì Chánh Pháp, thời hiện tại ứng trực theo lời nguyện thi hành.
          </p>
          <p>
            Ta. Tuyên rõ: Kể từ ngày mùng chín, tháng chín, năm Ất Mão (09-09-Ất-Mão). Tức ngày 13-10-1975, lúc 3 Giờ 20 Sáng. Trong Tam Giới cũng như ngoài Tam Giới. Trong Tam Thiên Đại Thiên Thế Giới cũng như ngoài Tam Thiên Đại Thiên Thế Giới. Tất cả Cảnh Giới đồng các Cõi Rồng-Người, Càng-Cát Đà-La-Ni trên muôn loài vạn vật, tất cả Quốc Vương, Dân Vương, Chủ Vương, Quyên Vương đến Chủ Vương cai quản từ một nước đến mười nước ở trong hoặc ngoài Tam Thiên Đại Thiên Thế Giới, cùng các Cõi Rồng-Người Cõi Trời thảy đều đồng đẳng chủ quyền rộng rãi, mức sống thoải mái không có sự ép buộc đè ép nhau, phải tuân hành, làm cho một nước Ác Trược, phiền nào trược trở thành Tịnh Độ Quốc Độ an lạc. Đối với tứ loài cũng hưởng an lạc chủ quyền thoải mái đồng đẳng đủ mọi phương cách.
          </p>
          <p>
            Ta. Tuyên rõ HỊCH nầy: Chư Bồ Tát, chư hộ pháp, hộ pháp bồ Tát cùng với Chư Thiên các Chư Thần các Nhân Thần, các Địa Thần Sơn Thần cùng Đẳng Đẳng Thần đồng với Long Thần Tứ Bộ hộ trì giúp đỡ công bằng giúp đỡ, thiện ác vẫn giúp đỡ, nếu có Chúng Sanh chưa hồi hướng nên giúp đỡ hồi hướng chỉ trừ trường hợp mỗi Chúng Sanh, mỗi Cá Tánh, mỗi Vương Quốc, mỗi Dân Vương, mỗi Chủ Vương, mỗi nơi mỗi chỗ của Quyền Vương Chủ Vương cai quản bảo thú Ác căn Nghiệt căn thời chư vị nên giúp đỡ cho chúng trở về nơi đồng chủng, đồng tánh của họ mà an hưởng nơi chí nguyện nghiệp quả như ý nguyện.
          </p>
        </PageSection>
        <PageSection sectionName={'images'}>
          <img src={vutru1} />
          <img src={vutru2} />
        </PageSection>
      </Page>
    );
  }
}
