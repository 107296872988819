import './index.scss';

import * as React from 'react';
import { Route } from 'react-router-dom';
import Page, { PageSection } from '../../shared/page';
import paths from '../../shared/url';
import getAlbumPages from './albums';

export class AnhHoatDongPage extends React.Component {
  render() {
    const { optionalClassName, albumTitle, images } = this.props;
    return (
      <Page pageName={'AnhHoatDong2Page ' + optionalClassName} pageTitle={albumTitle}>
        <PageSection sectionName={'gallery'}>
          <div className={'gallery-container'}>
            {images.map(imageSrc => {
              if (imageSrc.startsWith('https://www.youtube.com/embed')) {
                return (
                  <div className={'image'} key={imageSrc}>
                    <iframe width="560" height="315" src={imageSrc} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    <video src={imageSrc} />
                  </div>
                )
              }
              return (
                <div className={'image'} key={imageSrc}>
                  <img src={imageSrc} />
                </div>
              );
            })}
          </div>
        </PageSection>
      </Page>
    );
  }
}

const PageRoutes = (function() {
  let subRoutes = getAlbumPages().map(page => {
    let { urlPath, title, images } = page;
    return (
      <Route key={urlPath} path={urlPath} element={
        <AnhHoatDongPage albumTitle={title} images={images} />
      } />
    )
  });

  return (
    <Route path={paths.anhHoatDong}>
      {subRoutes}
    </Route>
  );
})();

export default PageRoutes;

export function AlbumList(props) {
  return (
    <div className={'AnhHoatDong'}>
      <ul className={'album-list'}>
        {getAlbumPages().map((album, idx) => {
          let { urlPath, title } = album;
          return (
            <li key={'gallery'+idx} className={'item'}>
              <a href={paths.anhHoatDong + '/' + urlPath}>{title}</a>
            </li>
          );
        })}
      </ul>
    </div>
  )
}