import './index.scss';
import * as React from 'react';
import { Link, Route } from 'react-router-dom';
import Page, { PageSection, PageSection2 } from '../../shared/page';

import Chuong1 from './chuong1';
import Chuong2 from './chuong2';
import Chuong3 from './chuong3';
import Chuong4 from './chuong4';
import Chuong5 from './chuong5';
import Chuong6 from './chuong6';
import Chuong7 from './chuong7';
import Chuong8 from './chuong8';
import Chuong9 from './chuong9';
import Chuong10 from './chuong10';
import Chuong11 from './chuong11';
import { useEffect } from 'react';
import DocTiep, { DocTiepPages } from '../../shared/doctiep';

const chapters = [
  <Chuong1 />,
  <Chuong2 />,
  <Chuong3 />,
  <Chuong4 />,
  <Chuong5 />,
  <Chuong6 />,
  <Chuong7 />,
  <Chuong8 />,
  <Chuong9 />,
  <Chuong10 />,
  <Chuong11 />,
]

const PageRoutes = (function() {
  const basePath = '/luoc-su-duc-thay/';
  const subRoutes = [];
  for (let i = 0; i < chapters.length; i++) {
    const component = chapters[i];
    let thisChapter = `chuong-${i+1}`, prevChapter = `chuong-${i}`, nextChapter = `chuong-${i+2}`;
    if (i === 0) {
      prevChapter = null;
    }
    if (i === chapters.length-1) {
      nextChapter = null;
    }
    subRoutes.push(
      <Route key={thisChapter} path={thisChapter} element={
        <ChuongN key={thisChapter}
                 prev={prevChapter && basePath+prevChapter}
                 next={nextChapter && basePath+nextChapter}
                 component={component}
        />
      } />
    );
  }

  return (
    <Route path={basePath}>
      {subRoutes}
    </Route>
  );
})();

function ChuongN(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const hasPrev = !!props.prev;
  const hasNext = !!props.next;
  return (
    <Page pageName={'LuocSuPage'} twoLineTitle={[
      'Đức Di Lạc Vô Thượng Tôn Phật',
      'Lược Sử Hiện Thể',
    ]}>
      <div className={'chuong-container'}>
        {props.component}
      </div>
      <PageSection sectionName={'navigator'}>
        {hasNext && <Link className={'next'} to={props.next}>Đọc tiếp</Link>}
        {hasPrev && <Link className={'prev'} to={props.prev}>Quay lại</Link>}
      </PageSection>
      {!hasNext && (
        <PageSection2 sectionName={'docTiep'}>
          <DocTiep pages={[DocTiepPages.phapMon, DocTiepPages.kinhSach, DocTiepPages.veChungToi]}/>
        </PageSection2>
      )}
    </Page>
  );
}

export default PageRoutes;
