import * as React from 'react';
import './index.scss';
import Page, { PageSection } from '../../shared/page';

import thongLenh1 from '../../../images/luocsu/thonglenh_1968_p1.PNG';
import thongLenh2 from '../../../images/luocsu/thonglenh_1968_p2.PNG';

export default class HichHoiThuongPage extends React.Component {
  render() {
    return (
      <Page pageName={'HichBoTatPage HichPage'} pageTitle={'Hịch Chứng minh Ban hành Pháp Tạng Hội Thượng'}>
        <PageSection sectionName={'text'}>
          <p>
            Đại diện Tam Thế Phật trọn quyền đảm trách thi hành lãnh đạo theo Tôn Chỉnh bổn nguyện chung của Tịnh Vương Phật trên con đường Pháp Tạng.
          </p>
          <p>
            Tăng chủ có trọng trách CHỨNG MINH cho các Chân tử cùng chứng minh giữa thời mạt pháp đưa tất cả tri kiến giải thoát hiện tại. Vậy các Chân tử hãy tìm tòi đâu mà đặng giải thoát, chỉ Tin Vâng Kính, Tin Vâng Kính Tăng Chủ liền đắc.
          </p>
          <p><i>
            Nếu mất Tin : Thì lạc loài sanh tử.
            Nếu mất Tin Vâng : Bị chìm đắm trong lục đạo để diễn hành.
            Nếu mất Tin Vâng Kính: Ngạ quỷ Súc sanh Địa ngục cận kề.
            Chẳng Tin: Không bao giờ giải thoát.
            Không Vâng: Chẳng lúc nào Đến và Đặng. Chẳng Kính: Phải bị xa lìa Tánh Trí, xa lìa Ba Đời Phật.
          </i></p>
          <p>
            Tin Vâng Kính trọn là Công Đức Hạnh Nguyện Cúng Dường Tam Thế, là thọ ký thành Phật, lại chung cùng bổn nguyện Pháp Tạng được đồng nguyện đồng hành cùng đồng sự chung với Bồ Tát Phật.
          </p>
          <h4>TĂNG CHỦ TỊNH VƯƠNG CHỨNG MINH.</h4>
          <p>
            Các Chân tử tề tựu.
          </p>
          <p>
            Từ Nhân Thiên, Thiên Thần tam giới lục đạo như : Nhân, A tu la, Súc sanh, Ngạ quỷ, Địa ngục, tuân hành nghe lãnh tề tựu.
          </p>
          <p>
            Chư Bồ tát Ma ha tát hộ trì giúp đỡ hữu hiệu.
          </p>
          <p>
            Chư Long Thần hộ pháp, Hộ pháp Bồ tát Ma ha tát hộ trì giúp đỡ hữu hiệu.
          </p>
          <p>
            Đồng mời Mười hai Dược sư Dạ xoa Quỷ Vương Đại Tướng của Đông độ Dược Sư hộ trì hữu hiệu.
          </p>
          <p>
            Các vị hãy vì Bổn Nguyện Chung của Tam Thế Phật. Hôm nay Tăng Chủ thừa hành thời mạt pháp chứng minh các Chân tử đồng thi hành nhiệm vụ chung cốt đưa tất cả tri kiến giải thoát.
          </p>
          <p>
            Vậy tùy theo mỗi Chân tử mà hộ trì giúp đỡ nơi Chánh báo và Thọ báo phân minh hiện hành đúng đắn hữu hiệu.
          </p>
        </PageSection>
        <PageSection sectionName={'images'}>
          <img src={thongLenh1} />
          <img src={thongLenh2} />
        </PageSection>
      </Page>
    );
  }
}
