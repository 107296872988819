import * as React from 'react';

import {Image} from './utils';
import quinhon1 from '../../../images/luocsu/quinhon1.png';
import quinhon3 from '../../../images/luocsu/quinhon3.png';
import quinhon4 from '../../../images/luocsu/quinhon4.png';
import quinhon5 from '../../../images/luocsu/quinhon5.png';
import quinhon6 from '../../../images/luocsu/quinhon6.png';

export default function Chuong5() {
  return (
    <div className={'section chuong 5-ha-lai-dong-do-2'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>V. Thời Hạ Lai Đồng Độ (phần 2, Qui Nhơn)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1970, Đức Tịnh Vương giá lâm chứng minh Pháp bảo Tỉnh
            Thị hội Bình Định, Qui Nhơn tại 123 đương Lê Lợi.
          </p>
          <Image src={quinhon1}
                 height={200}
                 caption={'Đức Tịnh Vương tại tịnh thất Pháp bảo Tỉnh Thị hội Bình Định, Qui Nhơn. Ngày 20-06-1970.'} />
          <Image srcs={[quinhon3, quinhon4, quinhon5]}
                 height={200}
                 caption={'Tứ chúng Qui Nhơn sinh hoạt đạo tràng.'} />
          <Image src={quinhon6}
                 height={250}
                 caption={'“Tôi xin giới thiệu là bà vợ của ông Linh Quang Viên, đương kim làmtrung tướng ở tại thành phố Nha Trang. Khi bấy giờ, bà Nguyễn Thị Nghĩa là vợ\n' +
                   'trung tướng Viên, nhân cuộc hành hương ra Huế, bà dẫn một phái đoàn ra Huế\n' +
                   'hành hương, trong lúc bấy giờ Tôi giá lâm Đà Nẵng, hai bên gặp nhau, yêu cầu\n' +
                   'để cho họ dự lễ, khi Tôi giá lâm tại Qui Nhơn thì mời tất cả chân phật tử Pháp\n' +
                   'Tạng về để làm lễ và đồng thời để nghe thuyết pháp. Đây là hình ảnh bà Nguyễn\n' +
                   'Thị Nghĩa đứng lên nói lên nguyện vọng của mình khi đã gặp được Pháp Tạng\n' +
                   'là chuyên ròng về giáo lý, thiền tọa tu Phật, không có dùng màu sắc gì để mà\n' +
                   'gọi là chính trị; Bà nói lên nguyện vọng ưa thích của mình đối với Pháp Tạng”. (trích di ngôn Đức Tăng Chủ).'}
          />
        </div>
      </div>
    </div>
  );
}
