import * as React from 'react';

import * as utils from './utils';
import hue1 from '../../../images/luocsu/hue1.png';
import hue2 from '../../../images/luocsu/hue2.png';

export default function Chuong7() {
  return (
    <div className={'section chuong 7-ha-lai-dong-do-4'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>VII. Thời Hạ Lai Đồng Độ (phần 4, Huế)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1974, Đức Tăng chủ chứng minh Pháp bảo Thị hội Huế. Năm nầy tại Trung ương
            Hội thượng, Ngài truyền <i>"Giáo ngôn"</i>, và <i>"Bảo Phẩm Xuất Thế Chơn kinh"</i>.
          </p>
          <utils.Image src={hue1}
                 height={400}
                 caption={'"Đây là cuộc hành hương, lúc\n' +
                   'bấy giờ bà vợ Tôi là Phan Thị Kim Yến\n' +
                   '(người thứ 3 từ trái) đi với tứ chúng nữ\n' +
                   'hành hương các chùa trong tỉnh\n' +
                   'Khánh hòa". [lời Di ngôn]. (người thứ\n' +
                   '4 từ trái: bà Nguyễn Thị Nghĩa là vợ\n' +
                   'trung tướng Viên. Ảnh tại tượng đài\n' +
                   'Quán Thế Âm trên đỉnh đèo Rù rì Nha\n' +
                   'trang 1974).'} />
          <utils.Image src={hue2}
                 height={300}
                 caption={'"Khi ông Linh Quang Viên sắp đổi đi để làm đại sứ\n' +
                   'nước ngoài (Ma rốc) thì ông mới mời Tôi một bửa cơm thân mật để tiễn\n' +
                   'đưa ông ra nước ngoài làm đại sứ" [lời Di ngôn]. (ngồi giữa: Đức Tăng\n' +
                   'Chủ. Thứ 3 và 5 từ trái: Ông Linh Quang Viên (đeo kính) và Phu nhân\n' +
                   'Nguyễn Thị Nghĩa (pháp danh Tạng Thảo)'} />
        </div>
      </div>
    </div>
  );
}
