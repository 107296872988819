import './index.scss';
import LotusDivider from '../divider';
import NavBar from '../navbar';
import Footer from '../footer';

export default function Page(props) {
  const {
    pageName,
    pageTitle, twoLineTitle, // only one should be defined
  } = props;

  let title = null;
  if (!!pageTitle) {
    title = <PageSection sectionName={'heading'}>
      <h1>{pageTitle}</h1>
      <LotusDivider />
    </PageSection>;
  } else if (!!twoLineTitle) {
    title = <PageSection sectionName={'heading'}>
      <h1>{twoLineTitle[0]}</h1>
      <h2>{twoLineTitle[1]}</h2>
      <LotusDivider />
    </PageSection>
  }

  return (
    <div className={pageName}><div className={'contents-container'}>
      <PageSection sectionName={'navbar'}>
        <NavBar />
      </PageSection>
      {title}
      {props.children}
      <PageSection sectionName={'navbar'}>
        <Footer />
      </PageSection>
    </div></div>
  );
}

export function PageSection(props) {
  const { sectionName } = props;
  return (
    <div className={'section ' + sectionName}><div className={'contents'}>
      {props.children}
    </div></div>
  );
}

export function PageSection2(props) {
  const { sectionName } = props;
  return (
    <div className={'section section-2 ' + sectionName} id={sectionName}>
      <div className={'contents'}>
        {props.children}
      </div>
    </div>
  );
}
