import './index.scss';
import logo from '../../../images/logo.png';
import { MdEmail } from 'react-icons/md';
import { FaFacebook, FaYoutube } from 'react-icons/fa';

function Footer() {
  return (
    <div className={'Footer NavBar'}>
      <div className={'logo'}>
        <img src={logo} />
        <div className={'site-name'}>
          <h3>Pháp Tạng Phật Giáo Việt Nam</h3>
          <h2>Nhất Tôn Như Lai Tạng</h2>
        </div>
      </div>

      <div>
        <div className='items_1'> Liên hệ và theo dõi chúng tôi:</div>
        <div className={'items'}>
          <p className='circleM'><a className={'email'} href={'mailto:info@nhattonnhulaitang.com'}><MdEmail /></a></p>
          <p className='circleF'><a className={'facebook'} href={'https://s.net.vn/IMUI'}><FaFacebook /></a></p>
          <p className='circleY'><a className={'youtube'} href={'https://s.net.vn/J70T'}><FaYoutube /></a></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
