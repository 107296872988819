import './index.scss';
import anLenh from '../../../images/anlenh.png';

function FourLinePanel() {
  // text-align-last doesn't work in Safari, which we need to render the
  // four h1's correctly.
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    return (
      <div className={'FourLinePanelImage'}>
        <img src={anLenh} />
      </div>
    );
  }
  return (
    <div className={'big-word-panel-container'}>
      <div className={'FourLinePanel'}>
        <div className={'inner'}>
          <div className={'prelude'}>
            <h1 className={'di-lac-ton-phat'}>DI LẠC TÔN PHẬT</h1> 
            <h2 className={'an-lenh'}>ẤN LỆNH :</h2>
          </div> 
          <div className={'four-lines'}>
            <h1 className={'justify-all'}>
              THƯỢNG HẠ HOÀ AN HẢO<br/>
              SUY TÔN KHAI ĐẠO TRÀNG<br/>
              NHẤT NGÔN TRI KIẾN PHẬT<br/>
              HOÀN TẤT GIẢI THOÁT MÔN<br/>
            </h1>
          </div>
          <div className={'exitlude'}>
            <p className={'justify-all'}>
              Ngày rằm tháng sáu năm Tân Hợi, tức 5-8-1971<br/>
              Trung Ương Hội Thượng - Nha Trang - Việt Nam<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourLinePanel;
