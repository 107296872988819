import * as React from 'react';

import * as utils from './utils';
import hinh80 from '../../../images/luocsu/hinh80.png';
import hinh81 from '../../../images/luocsu/hinh81.png';
import hinh83 from '../../../images/luocsu/hinh83.png';
import hinh84 from '../../../images/luocsu/hinh84.png';

export default function Chuong10() {
  return (
    <div className={'section chuong 10-tan-do-ha-lai-3'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>X. Thời Tận Độ Hạ Lai (phần 3)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1980. Do duyên Ông Hà Văn Hùng (thầy giáo dạy thái cực quyền và khiêu vũ - cours de dance)
            đọc quyển Giáo Pháp của Tịnh Vương, Ông cùng vợ là bà Nga đồng
            đích thân đi tìm gặp tác giả theo địa chỉ ghi trên bìa sách Giáo Pháp.
          </p>
          <p>
            Trong buổi đầu tiên gặp Đức Tịnh Vương tại 42 Hồng Bàng, Ông bà
            phát tâm cung thỉnh Đức Tăng Chủ vào Sài gòn dạy đạo. Đức Tăng
            chủ chấp thuận. Ngài giá lâm chứng minh Pháp bảo thành phố Sài gòn
            Chợ lớn Gia định [thành phố Hồ Chí Minh] tại nhà Ông Hà Văn Hùng
            (pháp danh Pháp Tạo) và bà Nga (pháp danh Diệu Hoàn) ở số 351/53
            đường Trương Minh Giảng (sau đổi tên đường Lê Văn Sỹ), quận 3,
            thành phố Hồ Chí Minh, vào ngày 16 tháng 08 năm 1980 (06-7-Canh
            thân). Qua sáu năm Ngài đóng cửa đạo tràng, đến đây Ngài chứng
            minh khai giòng pháp mới "Tận độ hạ lai".
          </p>
          <utils.Image src={hinh80}
                       height={300}
                       caption={'Đức Tăng chủ (phải) và chân tử Hà Văn Hùng pháp danh Pháp Tạo (trái) – Người cung thỉnh Đức Tăng Chủ khai đạo thành phố HCM. Ảnh chụp tại viện\n' +
                         'bảo tàng Sài gòn, ngày 16 tháng 8 năm 1980.'} />
          <utils.Image src={hinh81}
                       height={300}
                       caption={'Đức Tăng chủ (phải) và chân tử Trần Phước Nhân pháp danh Pháp Tâm\n' +
                         '(trái). Ảnh chụp tại viện bảo tàng Sài gòn, ngày 16 tháng 8 năm 1980.'} />
          <utils.Image src={hinh83}
                       height={300}
                       caption={'Bàn viết nơi góc tịnh thất 42 Hồng Bàng Nha trang - 1981.\n' +
                         '(Tăng Chủ Tịnh Vương truyền các văn bản kinh pháp Như lai tạng tại đây)' +
                         'bảo tàng Sài gòn, ngày 16 tháng 8 năm 1980.'} />
          <utils.Image src={hinh84}
                       height={300}
                       caption={'Phút tịnh dưỡng tại tịnh thất 42 Hồ Bàng Nha trang'} />
        </div>
      </div>
    </div>
  );
}
