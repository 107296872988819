import './index.scss';
import paths from '../url';

function Index(props) {
  return (
    <div className={'DocTiep'}>
      <h2>Đọc tiếp</h2>
      {props.pages.map((page, idx) => {
        return (
          <div className={'page'} key={page.name}>
            <a href={page.href}>{page.name}</a>
          </div>
        );
      })}
    </div>
  );
}

export const DocTiepPages = {
  phapMon: { name: 'Pháp Môn Như Lai Tạng', href: paths.phapMon },
  kinhSach: { name: 'Kinh của Đức Di Lạc', href: paths.kinhSach },
  luocSu: { name: 'Lược sử Đức Di Lạc', href: paths.luocSu },
  veChungToi: { name: 'Về Chúng tôi', href: paths.veChungToi },
};

export default Index;
