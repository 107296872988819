import './index.scss';

import hoaMai from '../../../images/hoamai.png';
import FourLinePanel from '../../art/4linepanel';

import Page, { PageSection2 } from '../../shared/page';
import DocTiep, { DocTiepPages } from '../../shared/doctiep';

function PhapMonPage() {
  return (
    <Page pageTitle={'Pháp Môn Như Lai Tạng'}
          pageName={'AboutPage'}>
      <FourLinePanel />
      <PageSection2 sectionName={'intro'}>
        <p>
          Sự tu hành có nhiều lối và nhiều pháp môn. Tu lối nào hay pháp môn nào cũng chủ yếu Về Tâm.
          Vậy pháp môn hay đường lối tu tập đều là phương tiện để phá mê chấp đặng giác ngộ, nên tu
          lối nào cũng đặng, chỉ mau hoặc chậm mà thôi.
        </p>
        <p>
          Tuy hiểu rằng tỏ tâm là giác, mà làm thế nào để tâm tỏ? - Vì Tâm thì kinh Kim cang có nói
          "Ưng vô sở trụ nhi sanh kỳ tâm", nghĩa là Tâm không chỗ chỉ mới sanh tâm. Như vậy, đã không
          chỗ chỉ lấy chi để làm Tâm mà tu cho tỏ tâm đến giác ngộ?
        </p>
        <p>
          Vì sự khó ấy mà kẻ tu hành <i>phải có đường lối hay pháp môn</i>. Nơi đường lối hay pháp môn phải
          có vị Tăng chủ hoặc bậc Thiện tri thức đã giác ngộ chỉ bày tu hành mới thành tựu cùng tỏ
          tâm giác ngộ.
        </p>
      </PageSection2>
      <PageSection2 sectionName={'me-chap'}>
        <h4 className={'section-heading'}>Mê & Chấp</h4>
        <p>
          Nếu chẳng vậy thời tu cho có chứng tu, hay tu để cầu phước, vì sao? – <b>Vì đang Mê tu bằng cách
          nào vẫn trong Thời Mê</b>. Cũng như: Kẻ đang bị lạc đường không lối thoát thì dù có dìu dẫn kẻ khác
          cho có bạn thôi, nó cũng không hơn kém. Do vậy, kẻ tu hành tối cần nương tựa theo pháp môn và
          Tăng chủ Thiện tri thức đã tỏ ngộ mới mong cầu chân giác.
        </p>
        <p>
          Nếu theo pháp môn duy nhất Pháp Tạng mà tu thời chung gồm tất cả căn nghiệp của chân tử liền dùng
          đại phương tiện chỉ bày. Chân tử phải phát Bồ đề tâm và kiên dũng lập Giới Định Tuệ
          nhất tâm tu không ngừng nghỉ.
        </p>
        <p>
          Lại nữa, kẻ tu <b>cần phải sửa đổi</b> để tạo lấy Thiện căn cùng phải chịu tất cả các thử thách
          khi thuận lúc nghịch của thế gian hiện hành để đặng hiểu biết thế nào là Pháp. Nếu tỏ pháp
          liền đặng tỏ tâm, như vậy gọi là “nương pháp tỏ pháp” tức ngộ pháp. Những điểm chính yếu tu
          theo con đường Pháp Tạng Như Lai vậy.
          <div className={'img-container'}>
            {/*eslint-disable-next-line*/}
            <img
              style={{
                opacity: 0.8,
                width: 420,
                transform: 'rotate(180deg)',
                zIndex: -1,
              }}
              src={hoaMai} />
          </div>
        </p>
        <p>
          Con đường Duy nhất là một lối tu điều hòa không nặng nhẹ bên nào. Kinh Duy ma có câu:
        </p>
        <div className={'quote-with-marks'}>
          <p>
            Chớ nên cho mình là phàm phu, cũng chớ tưởng mình là thánh. Vì tưởng phàm bị thối, tưởng thánh bị tăng.
          </p>
        </div>
        <p>
          Do chỗ Chấp đó mà tu bị nặng nhẹ không đều, khó viên dung vào chân giác.
        </p>
        <p>
          Về con đường đến Chân giác nó thật là vi diệu, thường gần đối với bậc đại căn thù thắng, những bậc
          ấy không có ảo vọng xa xôi, chỉ lấy hiện tại làm căn bản tu. Đức Thế Tôn Ngài vẫn nói rất đơn giản như câu:
          <div className={'quote-with-marks'}><p>
            Bị lầm nơi pháp huyễn hoá, lấy pháp huyễn mà tu, khi huyễn sạch hết tức là ngộ.
          </p></div>
          Như chúng ta lầm mê trót nhận huyễn hóa là Chơn, chẳng khác nào như kẻ<b> đạp phải gai thời bị nhức; lể gai đặng thì liền hết nhức</b>.
          Nếu kẻ tu nào so như vậy, <i>chính là tu Phật</i>.
        </p>
      </PageSection2>
      <PageSection2 sectionName={'nghiep'}>
        <h4>
          Nghi<span style={{letterSpacing: 3}}>ệ</span>p
        </h4>
        <p>
          Kẻ tu hành rất cần nơi cỗi giải các chướng nghiệp vặt mắc đố tật. Những nghiệp ấy nó làm cho Tâm Tham
          sinh ra Sân Hận, nó lại làm cho kẻ tu hành bị chướng và cũng bị ngăn trên hoàn cảnh hành đạo cùng
          với trí tuệ không thông đạt đặng.
        </p>
        <p>
          Kẻ tu mà kiên cố cỗi giải thì liền <b> tâm ý rỗng rang, trí tuệ mở</b>. Cũng lại tu hành chớ nên vọng ước cao
          siêu một khi mà Tâm còn ngăn cách mê mờ lí bí, phải bị sa vào lối tu tưởng vọng. Những lối tu ấy<i> rất
          nhiều kẻ bị mắc phải</i>, không thể nào đoạt đến chân lý mà còn bị lầm theo ấn tưởng, lại còn thụ chấp
          con đường tu Phật là vậy.
        </p>
        <p>
          Do sự lầm sai biệt ấy mà Phật mới nói để chỉ thẳng cho các chân tử khỏi lầm, hoặc tu hành khỏi bị chìm đắm nơi huyễn pháp mà mắc phải nơi ấn tưởng ấy. Kinh Kim cang có bốn câu như sau:

          <div className={'untitled-poem'}>
            <p>Nhất thiết hữu vi pháp</p>
            <p>Như mộng huyễn bào ảnh</p>
            <p>Như lộ diệc như điển</p>
            <p>Ưng tác như thị quán</p>
          </div>
        </p>
        <p>
          Nghĩa là: Tất cả các pháp hữu vi đều là mộng, không thực, như hình bóng, như tia chớp, không thực có; nếu nhận lấy đều mù quáng.
        </p>
        <p>
          Lời nói của Đức Thích Ca Ngài thấy tỏ rõ chỗ lầm nhận vọng cầu của chúng sanh trên con đường tu hành sai lạc
          ấy mà Ngài chỉ bày thẳng, cốt để lại cho khỏi lầm, cho <b> tất cả kẻ tu đặng đến chân giác</b>.
        </p>
        <p>
          Vậy kẻ tu nên thận trọng, chớ nên thọ chấp các huyễn hoá mà nhận lãnh làm cho càng tu càng mê mờ thêm nữa,<i> thà rằng đừng tu còn hơn</i>!
        </p>
      </PageSection2>
      <PageSection2 sectionName={'thuan-hanh-dung'}>
        <h4>Thuận, Hành, Dụng</h4>
        <p>
          Con đường Pháp Tạng là một con đường tùy thuận mỗi hoàn cảnh, mỗi nghiệp căn của kẻ tu mà chỉ bày.
          Lại cũng tùy mỗi Giáo của mỗi chân tử ưa thích chỉ bày để mà tu. Chung gồm tất cả các Giáo trên của
          Phật dạy mà gom lại Duy nhất Phật thừa, cũng gọi là pháp môn Không Hai. Nơi Pháp Tạng lập pháp
          Thiền tọa để tỏ pháp tức là tỏ tâm.
        </p>
        <p>
          Đối với tinh thần Duy nhất Pháp Tạng, kẻ tu hành phải Tin Vâng Kính theo sự chỉ bày của Tăng chủ
          và phải dẹp bỏ tất cả lý chướng để tu tập đến lý sự không chướng, đó là <b>con đường tu tỏ tâm cùng khắp</b>.
        </p>
        <p>
          Duy nhất đại phương tiện Pháp Tạng dụng Tin Vâng Kính chỉ bày quyết định tu tri kiến giải thoát.
          <i> Nếu Tin Vâng Kính chẳng trọn khó thành tựu</i>.
        </p>
        <p>
          Và Ba điểm để cầu Như Lai mà tu đến Chân Giác là: <b>Thuận – Hành – Dụng</b>:
        </p>
        <p>
          <span className={'lead-word'}>Thuận</span> nương vào Như Lai để cầu Như Lai. Không lấy sắc
          tướng âm thanh mà cầu. Duy nhất chỉ thuận dòng mà đặng.
        </p>
        <p>
          <span className={'lead-word'}>Hành</span> với Như Lai để cầu Như Lai. Không lấy chỗ công đức mà cầu. Duy nhất nương vào công đức Như Lai Chung Cùng mà đặng.
        </p>
        <p>
          <span className={'lead-word'}>Dụng</span> Như Lai sẵn đồng dụng, nguyện nương vào Như Lai để cầu thuận nghịch đồng song đặng chân giác.
        </p>

        <div className={'sign-off'}>
          - Tịnh Vương Nhất Tôn -
        </div>
      </PageSection2>
      <PageSection2 sectionName={'docTiep'}>
        <DocTiep pages={[DocTiepPages.kinhSach, DocTiepPages.luocSu, DocTiepPages.veChungToi]}/>
      </PageSection2>
    </Page>
  );
}

export default PhapMonPage;
