import './index.scss';

function FiveWordPanel(props) {
  return (
    <div className={'FiveWordPanel ' + props.className}>
      <div className={'inner'}>
        <h1>{props.text}</h1>
      </div>
    </div>
  );
}

export default FiveWordPanel;
