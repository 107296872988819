import * as React from 'react';
import './index.scss';
import Page, { PageSection } from '../../shared/page';

export default class GiayPhepTPHCMPage extends React.Component {
  render() {
    return (
      <Page pageName={'GiayPhepPage'} twoLineTitle={['Giấy phép Sinh hoạt', 'Hồ Chí Minh']}>
        <PageSection sectionName={'picture'}>
          <img src={'/images/giayphepHCM.jpg'} />
        </PageSection>
      </Page>
    );
  }
}

export class GiayPhepQNPage extends React.Component {
  render() {
    return (
      <Page pageName={'GiayPhepPage'} twoLineTitle={['Giấy phép Sinh hoạt', 'Quảng Ngãi']} >
        <PageSection sectionName={'picture'}>
          <img src={'/images/giayphepQN.jpeg'}/>
        </PageSection>
      </Page>
    );
  }
}
