import * as React from 'react';
import './index.scss';
import Page, { PageSection } from '../../shared/page';

import botat1 from '../../../images/luocsu/botat1.png';
import botat2 from '../../../images/luocsu/botat2.png';

export default class HichBoTatPage extends React.Component {
  render() {
    return (
      <Page pageName={'HichBoTatPage HichPage'} pageTitle={'Hịch Ban hành Chư Bồ Tát, Bồ Tát Ma Ha Tát'}>
        <PageSection sectionName={'text'}>
          <p>
            Ngày 07 tháng 12 năm Bính Thìn, lúc 09 giờ 30 sáng.
          </p>
          <p>
            Trước sự hiện diện có Đại Bồ Tát, Bồ Tát Ma Ha Tát, cùng chư Long Thần Hộ Pháp, Hộ Pháp Bồ Tát, Sơn Thần, Địa Thần, Thượng Đẳng Đẳng Chánh Thần, A Tu La Thần đầy đủ tề tựu.
          </p>
          <p>
            Đức Tăng Chủ Tịnh Vương Nhất Tôn ban hành Hịch từ hàng Bồ tát ấn giáo lại cho chư Thiên, chư Long Thần Hộ Pháp, Hộ Pháp Bồ Tát, Sơn Thần, Địa Thần, Thượng Đẳng Đẳng Chánh Thần, A Tu La Thần. Đức Tịnh Vương tuyên giáo:
          </p>
          <p>
            Nầy các Ông cũng nên biết : Chúng sanh vô biên thề nguyện độ, Như Lai vô biên thề nguyện sự, Vô thượng đẳng Chánh giác thệ nguyện thành. Từ các hàng Bồ Tát phải thi hành Ma Ha Tát cốt nương vào Vô ngã để trọn lãnh Chánh ngã Đại giác, đó chính là con đường duy nhất của chư Bồ Tát phải thực hành tu chứng. Khi đã đại giác liền đến Đại Ngã ấn chứng Chân Ngã Chánh Giác gọi là Vô Thượng Đẳng Chánh Giác thì bậc nầy phải thành tựu thực hành hiện tướng đầy đủ chung khắp không thiếu sót để Tận Độ chúng sanh, Tận Độ tất cả Hữu tướng cùng Phi tướng rốt ráo Đại Niết Bàn. Nơi thị hiện tận độ hàng vạn Tướng hằng hà sa số Tướng, vô lượng Tướng nhưng chẳng lấy Một Tướng.
          </p>
          <p>
            Các Ông chớ vì Hữu tướng thị hiện của bậc Chánh giác, chớ vì Diệu dụng của bậc Đại giác mà bê trễ sự hiện hành Đồng ứng của Như Lai Tạng. Nếu bê trễ của các Ông thì làm gì hữu quá Tận độ chúng sanh, làm gì trọn nguyện Như Lai vô biên thề nguyện Sự. Đương thời Hạ lai Mạt pháp, các Ông muốn tận gốc hãy hộ trì chánh pháp đầy đủ các Tướng, khi bậc Chánh giác ban hành, các Ông phải thực hành tận độ đồng ứng đồng hiện với bậc Chánh giác để thành tựu.
          </p>
          <p>
            Lời nầy lời nói khó nghe, khó hiểu, khó biết, duy chỉ có Đại Bồ Tát cùng Bồ tát thi hành Ma ha tát giáo truyền cho chư Thiên, chư Long Thần Hộ Pháp, Hộ Pháp Bồ Tát, Sơn thần, Địa thần, Thượng Đẳng Đẳng chánh thần, A tu la thần, lớp lớp được thấu đạt.
          </p>
        </PageSection>
        <PageSection sectionName={'images'}>
          <img src={botat1} />
          <img src={botat2} />
        </PageSection>
      </Page>
    );
  }
}
