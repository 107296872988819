import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from "./components/pages/landing";
import PhapMonPage from "./components/pages/phapmon";
import LuocSuPageRoutes from "./components/pages/luocsu";
import KinhSachPage from './components/pages/kinhsach';
import GiayPhepTPHCMPage, { GiayPhepQNPage } from './components/pages/giayphep';
import HichBoTatPage from './components/pages/hich/botat';
import HichVuTruPage from './components/pages/hich/vutru';
import HichHoiThuongPage from './components/pages/hich/hoithuong';
import AboutUsPage from './components/pages/aboutus';
import paths from './components/shared/url';
import AnhHoatDongRoutes from './components/pages/anhhoatdong';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={'/'}>
            <Route index element={<LandingPage/>} />
            <Route path={paths.phapMon} element={<PhapMonPage/>} />
            <Route path={paths.kinhSach} element={<KinhSachPage/>} />
            <Route path={paths.veChungToi} element={<AboutUsPage/>} />
            {LuocSuPageRoutes}
            {AnhHoatDongRoutes}

            <Route path={paths.giayPhepTPHCM} element={<GiayPhepTPHCMPage/>} />
            <Route path={paths.giayPhepQN} element={<GiayPhepQNPage/>} />
            <Route path={paths.hichBoTat} element={<HichBoTatPage/>} />
            <Route path={paths.hichVuTru} element={<HichVuTruPage/>} />
            <Route path={paths.hichHoiThuong} element={<HichHoiThuongPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
