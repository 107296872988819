import { Image, Poem } from './utils';
import nguyenThiPhung from '../../../images/luocsu/nguyenthiphung.PNG';

export default function Chuong1() {
  return (
    <div className={'section chuong 1-duong-sanh-ha-lai'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>I. Thị Hiện Đương Sanh Hạ Lai</span>
        </div>
        <div className={'text'}>
          <p>
            Đức A Dật Đa Di Lạc Tôn Phật hạ sanh ngày 24 tháng 12 năm
            Kỷ Mùi 1918, trong một gia
            đình họ Từ. Phụ thân Ngài là cụ Từ Như Ý, nguyên quán
            huyện Chéo An, tỉnh Phúc Kiến, Trung Quốc. Ông Nội
            và Cha di trú sang định cư tại tỉnh Bình Định miền Trung Việt
            Nam từ thời Chiến tranh Thế giới lần thứ Nhất.
          </p>
          <p>
            Thuở thiếu thời Ngài sống cùng cha và mẹ, cụ bà Nguyễn Thị
            Phụng, tại thị trấn Bồng Sơn, huyện Tam Quan, tỉnh Bình Định.
          </p>
          <Image src={nguyenThiPhung} height={300}
                 caption={'Bà Nguyễn Thị Phụng'}/>
          <p>
            Thế danh Ngài là Từ Thế Thọ. Ngài là người con thứ sáu trong
            bảy anh em cùng giòng mẹ cả, với năm người em giòng mẹ kế, cùng
            chung sống trong một gia đình. Cha nghiện ngập mẹ tảo tần, sinh
            nhai bằng nghề buôn bán thường tình. Suốt quảng đời niên thiếu
            Ngài phải sớm gánh vác nuôi sống gia đình.
          </p>
          <Poem title={'Má Tôi'}  lines={[
            'Má tôi đang đảm cả chồng con.',
            'Tóc bạc đầu xanh chịu mỏi mòn.',
            'Buổi sáng lo tiền chồng có bệnh.',
            'Canh chiều chạy gạo trẻ đòi cơm.',
            'Trọn tình làm mẹ thương con bé.',
            'Vẹn nghĩa cùng cha sánh nước non.',
            'Sao má ra đời chi khổ thế!',
            'Một chồng thêm gánh bảy người con.',
          ]} source={'Từ Thế Thọ 20/02/1965'} />
        </div>
      </div>
    </div>
  );
}
