import * as React from 'react';
import './index.scss';
import Page, { PageSection2 } from '../../shared/page';
import paths from '../../shared/url';
import DocTiep, { DocTiepPages } from '../../shared/doctiep';
import { AlbumList } from '../anhhoatdong';

export default class AboutUsPage extends React.Component {
  render() {
    return (
      <Page pageTitle={'Về Chúng Tôi'} pageName={'AboutUsPage'}>
        <PageSection2 sectionName={'nhu-lai-thien'}>
          <h4>Như Lai Thiền</h4>
          <p>
            Như Lai Thiền là một môn chung gồm sáu cửa thiền môn nên gọi là
            Nhất Tôn Như Lai Thiền, đầy đủ hiển giáo mật tôn, Hiển Mật song tu,
            ẩn hiện duy nhất. Như Lai Thiền có <span className={'gold'}>Tông - Chỉ - Thể - Dụng</span>,
            nương theo Hành Dụng Như Lai cốt tỏ ngộ như sau:
          </p>
          <ul>
            <li>Lấy HÀNH DỤNG NHƯ LAI lập TÔNG.</li>
            <li>Lấy KHÔNG TRỤ lập CHỈ.</li>
            <li>Lấy GIÁC lập THỂ.</li>
            <li>Lấy HẠNH NGUYỆN lập DỤNG.</li>
          </ul>
        </PageSection2>
        <PageSection2 sectionName={'dao-trang'}>
          <h4>Sinh hoạt Đạo tràng</h4>
          <p>
            Cơ sở đạo tràng Giáo Thiền do Chân Phật tử Như Lai tạng tư nguyện phát tâm
            dựng xây tôn chỉnh tại gia trang mình, trên tinh thần Đời Đạo song
            đồng, vừa thực hiện hồi hướng công đức trang nghiêm Tịnh độ Quốc độ Phật,
            toạ thiền, tham cứu tu Phật, lại vừa phụng hành Như Lai Tạng thừa tuyên
            chánh pháp Nhứt thừa cúng dường Như Lai.
          </p>
          <p>
            Tôn chỉ, mục đích:
          </p>
          <p>
            Quy chế quản trị tự tánh đem đến an lành cho từng con người, gia đình, xã
            hội; lập nền tảng đạo đức của mỗi cá nhân phải có trách nhiệm đối với gia
            đình và xã hội.
          </p>
          <p>
            Các Đạo tràng Nhất Tôn Như Lai Tạng:
          </p>
          <p style={{marginTop: 25}}>
            <b>1. Đạo tràng Như Lai Thiền</b><br />
            Số 09 Lê Đại Hành, Tổ 1, phường Chánh Lộ, thành phố Quảng Ngãi, Việt Nam.<br/>
            <a href={paths.giayPhepQN}>Giấy phép</a> số 01/GCN-UBND ngày 12/06/2023.
          </p>
          <p>
            <b>2. Đạo tràng Di Lạc</b><br />
            Số 128-130 Ngô Tất Tố, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh, Việt Nam.<br/>
            <a href={paths.giayPhepTPHCM}>Giấy phép</a> số 01/GCN-UBND ngày 01/12/2023.
          </p>
          <p>
            <b>3. Đạo tràng tỉnh Bà Rịa - Vũng Tàu, Việt Nam.</b>
          </p>
          <p className={'contact-email'} style={{marginTop: 25}}>
            Liên lạc qua email: <a href={'mailto:info@nhattonnhulaitang.com'}>info@nhattonnhulaitang.com</a>
          </p>
        </PageSection2>
        <PageSection2 pageTitle={'Ảnh Hoạt Động'} sectionName={'anh-hoat-dong'}>
          <h4>Ảnh Hoạt Động</h4>
          <AlbumList/>
        </PageSection2>
        <PageSection2 sectionName={'docTiep'}>
          <DocTiep pages={[DocTiepPages.phapMon, DocTiepPages.kinhSach, DocTiepPages.luocSu]}/>
        </PageSection2>
      </Page>
    );
  }
}
