import * as React from 'react';

import {Image, Poem} from './utils';
import danang1 from '../../../images/luocsu/danang1.PNG';
import danang2 from '../../../images/luocsu/danang2.PNG';
import danang6 from '../../../images/luocsu/danang6.PNG';
import danang3 from '../../../images/luocsu/danang3.PNG';
import danang5 from '../../../images/luocsu/danang5.PNG';
import anhai1 from '../../../images/luocsu/anhai1.PNG';
import anhai2 from '../../../images/luocsu/anhai2.PNG';
import anhai3 from '../../../images/luocsu/anhai3.PNG';
import anhai4 from '../../../images/luocsu/anhai4.PNG';
import anhai5 from '../../../images/luocsu/anhai5.PNG';

export default function Chuong6() {
  return (
    <div className={'section chuong 6-ha-lai-dong-do-3'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>VI. Thời Hạ Lai Đồng Độ (phần 3, Đà Nẵng)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1973, Đức Tăng chủ giá lâm chưng minh Pháp bảo Tỉnh Thị
            hội Quảng Nam, Đà Nẵng. Cơ sở được đặt tại nhà Ông Lương y
            Phùng Nồng, số 59 đường Trưng Nữ Vương, thành phố Đà Nẵng.
          </p>
          <Image srcs={[danang1, danang2, danang6, danang3]}
                 height={200}
                 caption={'Tứ chúng tề tựu cung nghinh Đức Tăng chủ giá lâm Chứng minh khai đạo Đà Nẵng, từ sân bay về cổng Pháp bảo. Ngày 09-09-1973.'} />
          <Image src={danang5}
                 height={300}
                 caption={'Đức Tăng chủ lưu bút lễ khai đạo Quảng Nam Đà Nẵng.'} />
          <Poem title={'Lưu bút Khai đạo. Quảng Nam Đà Nẵng.'}
                source={'Tịnh Vương Nhất Tôn, ngày 9 tháng 9 năm Quý Sửu 1973'}
                lines={[
                  'LƯU bút khai hoa viết sổ vàng',
                  'NIỆM tình Sư bổn trổ muôn phang.',
                  'KHAI thông vạn nẻo về Như trí.',
                  'ĐẠO rõ chân nguyên tỏ Niết bàn.',
                  'ĐÀ NẴNG cung nghinh ngày tháng chín.',
                  'QUI NHƠN chung lễ đẹp duyên khang,',
                  'NHỨT TÂM thường tịnh xây tam giới.',
                  'NGUYỆN đẳng Vương Tôn, Bát niết bàn./.',
          ]} />
          <p>
            Năm 1974, Đức Tăng chủ giá lâm chứng minh Pháp bảo Quận hội
            Quận 3 (An Hải), thành phố Đà Nẵng.
          </p>
          <Image srcs={[anhai1, anhai2, anhai3]}
                 height={200}
                 caption={'Tứ chúng Đà Nẵng cung nghinh Đức Tăng chủ giá lâm chứng mình Pháp bảo Quận hội Quận 3 - Đà Nẵng. Ngày 24-03-1974.'} />
          <Image srcs={[anhai4, anhai5]}
                 height={200}
                 caption={'Đức Tăng chủ khai thị & lưu bút lễ chứng minh Pháp bảo Quận hội Quận 3 - Đà Nẵng.'} />
        </div>
      </div>
    </div>
  );
}
