import * as React from 'react';

import * as utils from './utils';
import hinh72 from '../../../images/luocsu/hinh72.png';
import hinh75 from '../../../images/luocsu/hinh75.png';
import hinh76 from '../../../images/luocsu/hinh76.png';
import hinh77 from '../../../images/luocsu/hinh77.png';
import botat1 from '../../../images/luocsu/botat_small_1.png';
import botat2 from '../../../images/luocsu/botat_small_2.png';
import paths from '../../shared/url';

export default function Chuong9() {
  return (
    <div className={'section chuong 9-tan-do-ha-lai-2'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>IX. Thời Tận Độ Hạ Lai (phần 2)</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1977 Đinh tỵ. Đức Tăng Chủ chuyển khai giòng pháp Tận độ hạ lai.
            Đức Tăng Chủ sử dụng pháp bất tịnh nghịch hành để tận độ hạ lai.
            Ngày 25 tháng 01-1977 Đức Tăng Chủ truyền Hịch ban hành chư Bồ
            tát, Bồ tát Ma ha tát.
          </p>
          <utils.Image src={hinh72}
                       height={400}
                       caption={'Đức Tăng Chủ Tịnh Vương Nhất Tôn – tháng 01-1977. Ảnh thời điểm chuyển dụng pháp nghịch. Biểu tượng thời Đại lực Tôn Phật tận độ Hạ lai.'} />
          <utils.Image srcs={[botat1, botat2]}
                 height={400}
                 caption={<span>Ảnh văn bản Hịch ban hành chư Bồ tát, Bồ tát Ma ha tát ngày 07-12-1977. <a href={paths.hichBoTat} target={'_blank'}>Đọc văn bản.</a></span>} />
          <p>
            Năm 1978. Trong chuyến Ngài giá lâm Đà nẵng thăm các gia đình chân tử,
            thì tại nhà 42 Hồng Bàng Nha trang xảy vụ ra án mạng khiến vợ Ngài
            là bà Phan Thị Kim Yến bị tử thương tại nhà. Bấy giờ dư luận ngoài
            xã hội và trong gia đình đối với vụ án nầy đều ngầm cho rằng đây là
            một cuộc mưu sát mà kẻ chủ mưu là ông Từ Thế Thọ. Vụ án kéo dài 4
            năm sau tòa án tỉnh Khánh hòa mới phá được án, bắt và xét xử 5 thủ
            phạm, tòa tuyên 2 án tử hình 3 án tù giam với tội danh cướp của giết
            người. <b>Ông Từ Thế Thọ trắng án</b>. Kết thúc vụ án.
          </p>
          <p>
            Trước thời gian xảy ra vụ án, Ông Từ Thế Thọ đã kết tác cùng bà
            Nguyễn Thị Hạnh (pháp danh Tạng Trình) làm vợ kế, thời điểm xảy
            án mạng, bà Nguyễn Thị Hạnh đã có một con chung với ông, tên là Từ
            Vĩnh Sinh, sanh tại Sài gòn năm 1977.
            Chứng kiến diễn cảnh nầy, có đến trên bốn nghìn (4.000) vị tín đồ hội
            Pháp Tạng tâm sanh nghi chấp, bất tín bỏ tu. Bấy giờ số chân phật tử
            hội Pháp Tạng còn khoảng chừng mười vị.
          </p>
          <utils.Image src={hinh75}
                       height={200}
                       caption={'Ông Từ Thế Thọ cùng vợ kế Bà Nguyễn Thị Hạnh và hai con. Ảnh 1979.'} />
          <p>
            Năm 1979. Đức Ngài sắp xếp lại gia đình. Ngài chia gia sản cho sáu người
            con lớn kế thừa đúng theo pháp luật, cơ sở 42 Hồng Bàng được chia
            đôi cho hai người con trai. Ngài còn giao cho chân tử Pháp Thành tùy ý tìm chọn một căn nhà để
            bà Tạng Trình mua ở, bấy giờ chọn mua được nhà số 6 đường Huỳnh
            Thúc Kháng Nha trang (căn nhà trệt có sân thượng bê tông).
          </p>
          <utils.Image srcs={[hinh76, hinh77]}
                       height={300}
                       caption={'Đức Tăng Chủ (phải) truyền lửa. Ảnh tại tịnh thất 42- Hồng Bàng Nha trang. 1979.'} />
        </div>
      </div>
    </div>
  );
}
