import * as React from 'react';

import * as utils from './utils';
import hinh86 from '../../../images/luocsu/hinh86.png';
import hinh87a from '../../../images/luocsu/hinh87a.png';
import hinh87b from '../../../images/luocsu/hinh87b.png';
import hinh88a from '../../../images/luocsu/hinh88a.png';
import hinh88b from '../../../images/luocsu/hinh88b.png';
import hinh91 from '../../../images/luocsu/hinh91.png';
import { Poem } from './utils';

export default function Chuong10() {
  return (
    <div className={'section chuong 11-di-lac-ton-phat'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>XI. Di Lạc Tôn Phật chứng tri Phật đạo</span>
        </div>
        <div className={'text'}>
          <p>
            Năm 1983. Đức Tăng chủ Tịnh Vương Nhất Tôn hoàn tất Bổn Nguyện
            Tam Thế Phật, thân chứng Ba Thân viên mãn.
          </p>
          <utils.Image src={hinh86}
                       height={400}
                       caption={'Chân dung Đức Tăng chủ Tịnh Vương Nhất Tôn Ba thân viên mãn. Kết thúc giai đoạn Bồ Ứng Thân Phật, chuyển sang giai đoạn Phật Ứng Thân\n' +
                         'Bồ Tát. Ảnh tại Hội thượng 42- Hồng Bàng Nha trang, khi Ngài viết xong Giáo\n' +
                         'ngôn trực chỉ bài thứ 9 Hoa Pháp Tánh/tập I "Biệt Tôn Vô Thượng Đẳng - Ấn\n' +
                         'chỉ V". 1983.'} />
          <p>
            Năm 1985. Ngày 15 tháng 4 giải hội Pháp Tạng. Chính quyền Cách mạng
            tỉnh Khánh Hòa triệu tập ông Từ Thế Thọ hệ phái Pháp Tạng đến cơ
            quan Ủy ban phường Tân Lập Nha Trang. Hai bên (đại diện chính
            quyền và Ông Từ Thế Thọ đại điện hệ phái Pháp Tạng) họp liên tiếp
            trong hai ngày 14 và 15 tháng 4, cuối cùng hai bên kết thúc bằng một
            biên bản thỏa thuận chung vào ngày 15 tháng 4 (tức 26 tháng 02- nhuận năm Ất Sửu).
          </p>
          <utils.Quote source={'Phụng trích Di ngôn 1991'}>
            Ngày hôm nay thấy cơ hội an lành, Tôi muốn nói lên lịch sử Pháp
            Tạng để cho họ hiểu, chớ con người tu mà ở trong pháp môn mình
            không hiểu là pháp môn gì, tu như thế nào, thì làm sao mà tu! Đã vô
            tu thì phải hiểu cái môn đó mới tu được. Năm nay Tôi đã mệt mà già, 74 tuổi, thành ra tiếng nói không còn
            trong trẻo như xưa nhưng mà sự hướng dẫn Tôi cứng rắn hơn xưa
            mà kết quả hơn xưa gấp mấy lần, vì đạo Phật không có già, chân lý
            nó không có già. Do đó, tất cả tứ chúng tín ngưỡng cứ y theo tôn,
            Tôi dùng y tôn, y chỉ. Y chỉ có nghĩa là những cái nào Tôi vấp phải,
            Tôi phải dạy cho người đi sau tránh cái vấp của Tôi, đó gọi là y chỉ.
            Tôi xin chào tứ chúng. Tôi mong tứ chúng phát bồ đề tâm nguyện, đại nguyện đi Bồ tát
            hạnh để "dùng sắc thanh hương vị xúc pháp không nhiễm" là con
            đường Bồ tát hạnh đứng thứ nhất của các pháp môn.
          </utils.Quote>
          <p>
            Năm 1986. Ngày 01 tháng 10 (29-9-Bính Dần) Ngài đăng quang <b>Vô
            Thượng Chánh Đẳng Chánh Giác Vô Thượng Tôn</b>. Cực điểm của
            Mạt pháp cũng là cực điểm của Chánh pháp, Ngài tuyên chấm dứt hạ
            lai, điểm giao thừa bước sang Thượng nguyên Chánh pháp.
          </p>
          <utils.Image srcs={[hinh87a, hinh87b, hinh88a, hinh88b]}
                       height={400}
                       caption={'Chân dung Đức Tăng Chủ Vô Thượng Tôn trong ngày lễ đăng quang. Ảnh chụp ngày 29-9-Bính Dần tức 01-10-1986 tại Hội thượng 42- Hồng Bàng, Nha trang. Tôn tượng chân dung “Vô Thượng Tôn Thế Thọ”. (tứ chúng tạo tượng\n' +
                         '1987).'} />
          <utils.Image src={hinh91}
                       height={250}
                       caption={''} />
          <Poem title={'TẬN ĐỘ HẠ LAI'}
                source={'Tịnh Vương Nhất Tôn, Kỷ niệm thời Vô Thượng Tôn. 1986.'}
                lines={[
                  'Tất cả diễn hành trỗi khúc ca.',
                  'An nhiên tịch tịnh phổ Di Đà.',
                  'Nhìn sang Tây độ hương thanh thoát.',
                  'Ngó đến Đông phương nhánh trổ hoa.',
                  'Cực điểm kỳ công xây chánh pháp.',
                  'Đương lai sanh hạ mỗi mình Ta.',
                  'Mấy ai đã thấu đông tây diễn.',
                  'Một khúc sơn ca vạn lối hòa.',
                  'Ý nghĩa thâm sâu khó hiểu sâu.',
                  'Tận dùng hạnh nguyện thoát mong cầu.',
                  'Giữa thời Đông độ hòa Tây độ.',
                  'Chỉ một cành hoa tát biển sâu.',
                ]} />
        </div>
      </div>
    </div>
  );
}
