import './index.scss';
import logo from '../../../images/logo.png';
import MenuIcon from '../menuicon';
import paths from '../url';
import React, { useState } from 'react';

function NavBar() {
  const [show, setShow] = useState(false);

  return (
    <div className={'NavBar'}>
      <div className={'logo'}>
        <img src={logo} />
        <div className={'site-name'}>
          <h3>Pháp Tạng Phật Giáo Việt Nam</h3>
          <h2>Nhất Tôn Như Lai Tạng</h2>
        </div>
      </div>
      <div className={'items'}>
        <a href={paths.index}>Trang chủ</a>
        <MenuIcon show={show} onClick={() => setShow(!show)} />
        {show &&
          <div className={'list-pages'}>
            <a href={paths.phapMon}>Pháp Môn Như Lai Tạng</a>
            <a href={paths.kinhSach}>Tham khảo Kinh Sách</a>
            <a href={paths.luocSu}>Lược sử Đức Di Lạc</a>
            <a href={paths.veChungToi}>Về chúng tôi</a>
          </div>
        }
      </div>
    </div>
  );
}

export default NavBar;
