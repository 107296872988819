import * as React from 'react';

import {Quote, Image} from './utils';
import stamp1 from '../../../images/luocsu/stamp1.PNG';
import stamp2 from '../../../images/luocsu/stamp2.PNG';
import daoKy from '../../../images/luocsu/daoky.PNG';
import daoHieu   from '../../../images/luocsu/daohieu.PNG';
import govConfirmation from '../../../images/luocsu/govconfirmation.PNG';

function Emph(props) {
  return (
    <span>
      "<b><u>{props.text}</u></b>"
    </span>
  );
}

export default function Chuong4() {
  return (
    <div className={'section chuong 4-ha-lai-dong-do'}>
      <div className={'contents'}>
        <div className={'title'}>
          <span>IV. Thời Hạ Lai Đồng Độ (phần 1)</span>
        </div>
        <div className={'text'}>
          <p>
             Từ năm 1970 các vị chân Phật tử bấy giờ đã chuẩn bị hồ sơ
            hợp thức hóa tôn giáo "Pháp Tạng Tỳ kheo A di đà Phật" theo
            luật pháp đương thời của chính phủ miền nam Việt Nam, để thành
            lập "Giáo hội Pháp Tạng Phật giáo Việt Nam" Giáo hội suy tôn Ngài Tịnh Vương Nhất
            Tôn làm vị Tăng chủ lãnh đạo giáo hội.
          </p>
          <p>
            Kể từ ngày 2 tháng 8 năm 1971, Giáo hội Pháp Tạng Phật Giáo
            Việt Nam hoạt động chính thức công khai trên toàn lãnh thổ
            miền nam Việt Nam. Cơ sơ Trung ương Hội Thượng Giáo hội đặt
            tại số 42 đường Hồng Bàng, thành phố Nha Trang.
          </p>
          <p>
            Thời điểm này Đức Tăng chủ khai giòng pháp Hạ lai Đồng Độ -
            Di Lạc đồng độ giới sinh.
          </p>
          <Image srcs={[stamp1, stamp2]}
                 caption={'Tráp & mặt chữ ấn Tăng chủ'}
                 height={300} />
          <Quote source={'Phụng trích Di ngôn 12/01/1991'}>
            Trong thời kỳ suy tôn lên, thì Tôi chỉ ra bốn câu kệ, Tôi nói
            rằng: tất cả mọi tầng lớp hãy nghe bốn câu kệ này mà tu hành.
            Đời này đến đời sau luôn luôn phải vậy.
            {' '}<Emph text={'Thượng hạ hòa an hảo'} /> trên dưới đều hòa thì
            trong cái hội đều an hảo. <Emph text={'Suy tôn khai đạo tràng'} />
            {' '} suy tôn Tôi lên làm Tăng Chủ để Tôi khai đạo tràng. Thì nghe
            lời Tôi <Emph text={'Nhất ngôn tri kiến Phật'} />, khi mà nghe
            được một lời Tôi dạy rồi, lãnh hội được thì tri kiến Phật; mà
            tu <Emph text={'Hoàn tất thì giải thoát môn'} />.
          </Quote>
          <Image srcs={[daoKy, daoHieu]}
                 caption={'Ảnh Đạo kỳ, Đạo hiệu Pháp Tạng Phật Giáo Việt Nam'}
                 height={300} />
          <Image src={govConfirmation}
                 height={500}
                 caption={'Ảnh văn bản Nghị định chính phủ (Việt Nam Cộng hòa cũ) cấp Hội Pháp Tạng Phật giáo Việt Nam, ký ngày 02-08-1971.'} />

        </div>
      </div>
    </div>
  );
}
